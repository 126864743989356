@import '../app.scss';
.setup {
  .imin-tabctrl {
    padding-left: 25px;
  }

  .k-tabstrip-items ul li {
    display: inline-block;
    padding: 20px;
  }

  h2 {
    margin: 1rem 0;
  }

  li.active {
    text-decoration: underline;
  }

  li:hover {
    text-decoration: underline;
  }

  img {
    max-width: 800px;
  }

  .btn-default {
    color: #fff;
    background-color: #90a4ae;
  }

  .btn-primary--blue {
    background-color: $color-primary-blue;

    &:hover,
    &:focus {
      background-color: $color-primary-blue;
    }
  }

  .k-grid {
    margin: 20px 25px 0 25px;
  }
  /* remove the global styles as they upset positioning here on our nested tab views*/
  [ui-view].reset-ui-view {
    margin-top: 0;
    margin-left: 0;
  }
}

/**
    Grid / list related styles for the setup area in Runner
*/

.list-no-results {
  padding-top: 100px;
  height: 100px;
  font-size: 20px;
}

.list-actions {
  display: flex;
  justify-content: flex-end;
  margin-right: -20px;
}

.list-actions__action {
  cursor: pointer;
  font-size: 22px;
  margin-right: 15px;
  display: flex;

  .fa-light:hover {
    color: $color-highlight-blue;
  }
}

/**
    Common cross cutting styles
*/
.btn-default {
  color: #fff;
  background-color: #90a4ae;

  &:hover,
  &:focus {
    background-color: #90a4ae;
  }
}

.btn-primary {
  color: #fff;
  background-color: $color-action-green;

  &:hover,
  &:focus {
    background-color: $color-action-green;
  }
}

.delete-component-card {
  padding: 0 10px;
}

.ngdialog {
  /*form.ng-submitted {
                
        input.ng-touched.ng-invalid {
            border-bottom: 1px solid #F44336 !important;
            box-shadow: 0 1px 0 0 #F44336 !important;
        }
    }*/
  .card {
    box-shadow: none;

    .content {
      box-shadow: none;
      padding-top: 0;

      .title {
        padding-left: 0;
      }

      .input-field {
        .errorMessage {
          padding-bottom: 15px;
          margin-top: -12px;
        }
      }
    }
  }

  .delete-warnings {
    position: relative;
    padding: 0 7px;

    p {
      margin: 0;
    }

    .header {
      position: absolute;
      top: -10px;
      font-weight: bold;
      background-color: #fff;
      padding: 0 10px;
      margin-left: 5px;
    }

    .input-field {
      margin-top: 0;
    }

    label {
      font-size: 13px;
    }
  }

  .delete-warnings-container {
    margin-bottom: 25px;

    i {
      color: orangered;
    }
  }

  .delete-search {
    display: flex;
    flex-direction: column;
    padding: 5px;

    label {
      font-size: 13px;
      font-weight: 600;
    }

    .search-box {
      display: flex;
      flex-direction: column;

      hr {
        margin-bottom: 15px;
        margin-top: 0;
      }

      .search-row {
        width: 100%;
        display: flex;
        margin-bottom: 25px;

        p {
          padding-right: 3px;
        }
      }

      div {
        width: 50%;
      }
    }

    .actor-list {
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      [type='radio']:checked + label,
      [type='radio']:not(:checked) + label {
        padding-left: 23px;
      }
    }
  }
}
