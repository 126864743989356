@import '../../../../app.scss';

.governance-input-placeholder {
  position: relative;
}

.governance-input-placeholder.has-value .governance-placeholder {
  display: none;
}

.governance-placeholder {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  height: 25px;
  left: 28px;
  margin: auto;
  color: #bdbdbd;
  font-style: italic;
  line-height: 13px;
}

.governance-placeholder span {
  font-weight: 700;
}

.governance-search {
  min-width: 400px;
  text-indent: 28px;
  max-width: 1000px;
  padding-right: 2rem;
}

.governance-clear {
  margin-left: -20px;
  padding-right: 10px;
}

.governance-search-container .fa-times {
  color: $color-text-primary;
  font-weight: bold;
  cursor: pointer;
}

.governance-search-container .fa-times:hover {
  color: $blue;
}

.governance-search-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 13em;
  margin-top: 3px;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
}

.governance-search-container .fa-magnifying-glass {
  margin-right: -20px;
  display: inline-block !important;
  height: 32px !important;
  line-height: 20px !important;
  color: grey !important;
  margin-left: 4px;
}
